import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import SpaceActionButton from '@/shared/components/SpaceActionButton.vue';
import UnderConstruction from '@/shared/components/UnderConstruction.vue';
import { RoleType } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    components: {
        SpaceActionButton,
        UnderConstruction
    },
    props: {
        page: {
            type: String,
            default: 'venue'
        },
        isActive: {
            type: Boolean,
            default: true
        },
        slug: {
            type: String,
            default: 'test'
        }
    },
    setup(_, context) {
        const { root, emit } = context;
        const constructionsPopupVisible = ref(false);
        const statusParam = computed(() => root.$route.query.status);
        const shouldChangeStatus = ref(false);
        onMounted(() => {
            if (statusParam.value) {
                shouldChangeStatus.value = true;
                root.$scrollTo('.space-status', 1500, {
                    offset: -100
                });
            }
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isUpdateAllowed = computed(() => user.value?.role_type !== RoleType.VenueManager);
        const preview = () => emit('onPreview');
        const activate = () => {
            if (isUpdateAllowed.value) {
                shouldChangeStatus.value = false;
                emit('onActivate');
            }
        };
        const deactivate = () => {
            if (isUpdateAllowed.value) {
                shouldChangeStatus.value = false;
                emit('onDeactivate');
            }
        };
        const trash = () => {
            if (isUpdateAllowed.value) {
                emit('onTrash');
            }
        };
        return {
            constructionsPopupVisible,
            isUpdateAllowed,
            shouldChangeStatus,
            activate,
            deactivate,
            preview,
            trash,
            useGetLocalizedPath
        };
    }
});
