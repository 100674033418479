//
//
//
//
//
//

export default {
  props: {
    status: {
      type: String,
      default: 'active',
      validator: val => val === 'active' || val === 'inactive'
    },
    page: {
      type: String,
      default: 'venue',
      validator: val => val === 'space' || val === 'venue'
    }
  }
};
