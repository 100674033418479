//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalPopup from '@/shared/components/ModalPopup.vue';

export default {
  components: {
    ModalPopup
  }
};
